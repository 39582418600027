import React, { Component } from "react";

class Seeker extends React.Component {
  render() {
    return <div id="ff-compose"></div>;
  }
  componentDidMount() {
    var script = document.createElement("script");
    script.id = "ff-script";
    script.src =
      "https://formfacade.com/include/108884919606050272005/form/1FAIpQLSePBbdvkoAVu6t9FLSweq4JR9wyivUcR9AW_zg3jzMM7OMKzQ/classic.js?div=ff-compose";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  }
}

export default Seeker;
