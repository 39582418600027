import React, { Component } from "react";
import { Element } from "react-scroll";
import NavbarHome from "./base/NavbarHome";

import ContactUs from "./base/Contact-us";
import Hero from "./base/Hero";
import AboutUs from "./base/AboutUs";
import JobUpdates from "./base/JobUpdates";
import { Helmet } from "react-helmet";

export class Home extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Home || Hollyface</title>
          <meta
            name="description"
            content="Welcome to Hollyface Overseas Recruitment & placement Pvt. Ltd.
          This page guides you through the introductory of our website. Have a look at about the company, MD's message and adress and Upcoming interviews or current openings."
          />
          <h1>Hollyface, Home Page</h1>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="og:type" content="Home page" />
          <meta name="og:url" content="https://hollyface.com/" />
        </Helmet>
        <NavbarHome />
        <Element name="CarouselHero"></Element>
        <Hero />
        <Element name="AboutUs" id="about-us"></Element>
        <AboutUs />
        {/* <Element name="JobUpdates" id="job-updates"></Element>
        <JobUpdates /> */}
        <Element name="ContactUs" id="contact-us"></Element>
        <ContactUs />
      </div>
    );
  }
}

export default Home;
