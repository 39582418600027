import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Footer from "./components/base/Footer";
import Home from "./components/Home";
import JobSeeker from "./components/JobSeeker";
import JobProvider from "./components/JobProvider";
import Clients from "./components/Clients";
import Services from "./components/Services";
import errorPage from "./components/404";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/provider" exact component={JobProvider}></Route>
        <Route path="/seeker" exact component={JobSeeker}></Route>
        <Route path="/clients" exact component={Clients}></Route>
        <Route path="/services" exact component={Services}></Route>
        <Route path="*" component={errorPage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
