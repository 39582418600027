import React, { Component } from "react";
import { CountryDropdown } from "react-country-region-selector";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

import NavbarOthers from "./base/NavbarOther";
import PageTitle from "./base/PageTitle";
import axios from "axios";
// import TopBanner from './base/TopBanner';
import { Helmet } from "react-helmet";
import AlertError from "./base/AlertError";
import AlertSuccess from "./base/AlertSuccess";

// const validEmailRegex = RegExp(
//   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// );

const validPhone = RegExp(/^\d{10}$/);

// const validateForm = (errors) => {
//   let valid = true;
//   Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
//   return valid;
// };

export default class JobProvider extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     form: {
  //       fName: null,
  //       lName: null,
  //       country: null,
  //       email: null,
  //       company: null,
  //       mobile: null,
  //       message: null,
  //     },
  //     errors: {
  //       email: "",
  //       mobile: "",
  //     },
  //     alert: null,
  //     captchaSuccess: false,
  //     captchaData: null,
  //   };
  // }

  // selectCountry(val) {
  //   let tmp_form = this.state.form;
  //   tmp_form["country"] = val;
  //   this.setState({ form: tmp_form });
  // }
  // selectRegion(val) {
  //   this.setState({ region: val });
  // }
  // onPhnChange = (valid, string, object, value) => {
  //   if (valid == false) {
  //     var err = this.state.errors;
  //     err.mobile = "Invalid mobile number";
  //     this.setState({ errors: err });
  //   } else {
  //     var err = this.state.errors;
  //     err.mobile = "";
  //     var form = this.state.form;
  //     form.mobile = value;
  //     console.log(value);
  //     this.setState({ errors: err, form: form });
  //   }
  // };

  // handleChange = (event) => {

  //   event.preventDefault();
  //   const { name, value } = event.target;

  //   let errors = this.state.errors;
  //   switch (name) {
  //     case "email":
  //       errors.email = validator.isEmail(value) ? "" : "Email is not valid!";
  //       break;
  //     // case 'mobile':
  //     //   errors.mobile =
  //     //     validPhone.test(value)
  //     //       ? ''
  //     //       : 'Mobile number must contain 10 digits'
  //     //   break
  //     default:
  //       break;
  //   }
  //   let tmp_form = this.state.form;
  //   tmp_form[name] = value;
  //   this.setState({ errors: errors, form: tmp_form });
  // };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (validateForm(this.state.errors) && this.state.captchaSuccess) {
  //     axios
  //       .post("https://hollyface.com/api/add-provider", {
  //         form: this.state.form,
  //         captcha: this.state.captchaData,
  //       })
  //       .then((res) => {
  //         this.setState({
  //           alert: <AlertSuccess />,
  //         });
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //         setTimeout(() => {
  //           this.setState({
  //             alert: null,
  //           });
  //         }, 5000);
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           alert: <AlertError />,
  //         });
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //         setTimeout(() => {
  //           this.setState({
  //             alert: null,
  //           });
  //         }, 5000);
  //       });

  //     return true;
  //   } else {
  //     this.setState({
  //       alert: <AlertError />,
  //     });
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: null,
  //       });
  //     }, 5000);
  //     return false;
  //   }
  // };

  // captchaChange = (value) => {
  //   if (value != null) {
  //     this.setState({
  //       captchaSuccess: true,
  //       captchaData: value,
  //     });
  //   } else {
  //     this.setState({
  //       captchaSuccess: false,
  //     });
  //   }
  // };

  render() {
    return (
      <div>
        <Helmet>
          <title>Job Provider Form || Hollyface</title>
          <meta
            name="description"
            content="Welcome to Hollyface Overseas Recruitment & placement Pvt. Ltd.
          Want to tie up with Hollyface as a Job Provider? Here you are..."
          />
          <h1>Hollyface, Job provider page</h1>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="og:type" content="Job provider page" />
          <meta name="og:url" content="https://hollyface.com/" />
        </Helmet>
        {/* <TopBanner /> */}
        <NavbarOthers />
        {/* <PageTitle text="Job Provider form" mt="mt-10" /> */}
        <div className=" w-full flex justify-center mt-10">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeY-1AXxc6PydPGI9yWhXhOlj0SXHSzx8GQu3ECVpSuIV7xFA/viewform?embedded=true"
            width="640"
            height="1700"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
      </div>
    );
  }
}
