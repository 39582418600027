import React, { Component } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";

import NavbarOthers from "./base/NavbarOther";
import PageTitle from "./base/PageTitle";
// import TopBanner from './base/TopBanner'
import axios from "axios";

import AlertError from "./base/AlertError";
import AlertSuccess from "./base/AlertSuccess";
import { Helmet } from "react-helmet";
import Seeker from "./base/forms/Seeker";
// const validEmailRegex = RegExp(
//   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// );

const validPhone = RegExp(/^\d{10}$/);
const validPin = RegExp(/^\d{6}$/);
const validPassport = RegExp(/^[A-PR-WYa-pr-wy][0-9]\d\s?\d{4}[1-9]$/);

// const industries = [
//   {
//     name: "Automobile",
//     image: "automation.svg",
//   },
//   {
//     name: "Aluminium & Cladding",
//     // image: ''
//   },
//   {
//     name: "Branded Hotels",
//     // image: ''
//   },
//   {
//     name: "Construction & Infrastructure",
//     image: "telecom.svg",
//   },
//   {
//     name: "Hospitality",
//     // image: ''
//   },
//   {
//     name: "Facility management",
//     // image: ''
//   },
//   {
//     name: "Heavy Machinery / Industrial Products",
//     image: "machine.jpg",
//   },
//   {
//     name: "Healthcare",
//     image: "hospital.svg",
//   },

//   {
//     name: "IT",
//     image: "it.svg",
//   },
//   {
//     name: "Oil & Gas/ Refinery/ Petrochemical",
//     // image:
//   },
//   {
//     name: "MEP(Mechanical , Electrical & Plumbing)",
//     // image:
//   },
//   {
//     name: "Production/ Manufacturing",
//     // image: ' '
//   },
//   {
//     name: "Retail",
//     image: "retail.svg",
//   },
//   {
//     name: "Ship Repair & Maintenance",
//     image: "shipping.svg",
//   },
//   {
//     name: "Shutdown Maintenance",
//     // image: ''
//   },
//   {
//     name: "Transport & Logistics",
//     image: "automation.svg",
//   },

//   {
//     name: "Tele Communication",
//     image: "telecom.svg",
//   },
// ];

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
export default class JobSeeker extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     form: {
  //       fName: null,
  //       lName: null,
  //       date: null,
  //       month: null,
  //       year: null,
  //       technicalQualification: null,
  //       educationalQualification: null,
  //       indianExperience: null,
  //       overseasExperience: null,
  //       post: null,
  //       industry: null,
  //       passportNumber: null,
  //       passportType: null,
  //       validity: null,
  //       passportCity: null,
  //       residentialAddress: null,
  //       pincode: null,
  //       country: "India",
  //       region: null,
  //       city: null,
  //       email: null,
  //       mobileNo: null,
  //       whatsappNo: null,
  //       resume: null,
  //     },

  //     resume: null,
  //     errors: {
  //       email: "",
  //       whatsappNo: "",
  //       mobileNo: "",
  //       pinCode: "",
  //       passportNumber: "",
  //       resume: "",
  //     },

  //     alert: null,
  //     captchaSuccess: false,
  //     captchaData: null,
  //   };
  // }

  // selectRegion(val) {
  //   let tmp_form = this.state.form;
  //   tmp_form["region"] = val;
  //   this.setState({ form: tmp_form });
  // }

  // handleChange = (event) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;

  //   let errors = this.state.errors;
  //   switch (name) {
  //     case "email":
  //       errors.email = validator.isEmail(value) ? "" : "Email is not valid!";
  //       break;
  //     case "whatsappNo":
  //       errors.whatsappNo = validPhone.test(value)
  //         ? ""
  //         : "Mobile number must contain 10 digits";
  //       break;
  //     case "mobileNo":
  //       errors.mobileNo = validPhone.test(value)
  //         ? ""
  //         : "Mobile number must contain 10 digits";
  //       break;
  //     case "pincode":
  //       errors.pinCode = validPin.test(value)
  //         ? ""
  //         : "Pincode must be of 6 digits";
  //       break;
  //     case "passportNumber":
  //       errors.passportNumber = validPassport.test(value)
  //         ? ""
  //         : "Invalid passport number";
  //     default:
  //       break;
  //   }
  //   let tmp_form = this.state.form;
  //   tmp_form[name] = value;
  //   this.setState({ errors: errors, form: tmp_form });
  // };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (validateForm(this.state.errors) && this.state.captchaSuccess) {
  //     axios
  //       .post("https://hollyface.com/api/add-seeker", {
  //         form: this.state.form,
  //         captcha: this.state.captchaData,
  //       })
  //       .then(async (res) => {
  //         if (this.state.resume != null) {
  //           var form_data = new FormData();
  //           await form_data.append("resume", this.state.resume);

  //           await axios.post(
  //             "https://hollyface.com/api/add-resume",
  //             form_data,
  //             {
  //               headers: {
  //                 "Content-Type": "multipart/form-data",
  //               },
  //             }
  //           );
  //         }

  //         this.setState({
  //           alert: <AlertSuccess />,
  //         });
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //         setTimeout(() => {
  //           this.setState({
  //             alert: null,
  //           });
  //         }, 5000);
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           alert: <AlertError />,
  //         });
  //         window.scrollTo({ top: 0, behavior: "smooth" });
  //         setTimeout(() => {
  //           this.setState({
  //             alert: null,
  //           });
  //         }, 5000);
  //       });
  //     return true;
  //   } else {
  //     this.setState({
  //       alert: <AlertError />,
  //     });
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     setTimeout(() => {
  //       this.setState({
  //         alert: null,
  //       });
  //     }, 5000);
  //     return false;
  //   }
  // };

  // captchaChange = (value) => {
  //   if (value != null) {
  //     this.setState({
  //       captchaSuccess: true,
  //       captchaData: value,
  //     });
  //   } else {
  //     this.setState({
  //       captchaSuccess: false,
  //     });
  //   }
  // };
  // onFileChange = async (event) => {
  //   // Update the state
  //   if (event.target.files[0]) {
  //     if (!this.state.form.email) {
  //       var tmp = this.state.form;
  //       tmp.resume = null;
  //       var tmp2 = this.state.errors;
  //       tmp2.resume = "Fill up other details first";
  //       this.setState({ form: tmp, errors: tmp2 });
  //       return;
  //     }
  //     var nm = btoa(this.state.form.email).replace("=", "");

  //     var file = event.target.files[0];
  //     var ext = file.name.split(".").pop();
  //     if (ext !== "pdf" && ext !== "docx" && ext !== "doc") {
  //       var tmp = this.state.form;
  //       tmp.resume = null;
  //       var tmp2 = this.state.errors;
  //       tmp2.resume = "Choose pdf / doc / docx file format";
  //       this.setState({ form: tmp, errors: tmp2 });
  //       return;
  //     }
  //     var tmp3 = this.state.errors;
  //     tmp3.resume = "";
  //     this.setState({ errors: tmp3 });
  //     var blob = file.slice(0, file.size, file.type);
  //     var newFile = new File([blob], nm + "." + ext, { type: file.type });
  //     await this.setState({ resume: newFile });

  //     var f_tmp = this.state.form;
  //     f_tmp.resume = nm + "." + ext;
  //     await this.setState({ form: f_tmp });
  //   } else {
  //     this.setState({ resume: null });
  //     var tmp = this.state.form;
  //     tmp.resume = null;
  //     this.setState({ form: tmp });
  //   }
  // };

  // fileData = () => {
  //   if (this.state.resume) {
  //     return (
  //       <div className="p-4 text-gray-600 text-md">
  //         <h2 className="font-semibold">Resume Added</h2>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <br />
  //         <h4 className="p-4 text-gray-800 text-lg font-bold">
  //           Upload your resume
  //         </h4>
  //       </div>
  //     );
  //   }
  // };

  render() {
    // var days = [
    //   <option value="" selected={true} disabled={true}>
    //     DD
    //   </option>,
    // ];
    // var months = [
    //   <option value="" selected={true} disabled={true}>
    //     MM
    //   </option>,
    // ];
    // var years = [
    //   <option value="" selected={true} disabled={true}>
    //     YYYY
    //   </option>,
    // ];
    // var ind = [
    //   <option selected value="" disabled={true}>
    //     Select industry
    //   </option>,
    // ];

    // var i;
    // for (i = 1; i <= 31; i++) {
    //   days.push(<option key={i}>{i}</option>);
    // }
    // for (i = 1; i <= 12; i++) {
    //   months.push(<option key={i}>{i}</option>);
    // }
    // const d = new Date();
    // for (i = d.getFullYear() - 60; i <= d.getFullYear() - 15; i++) {
    //   years.push(<option key={i}>{i}</option>);
    // }
    // for (i = 0; i < industries.length; i++) {
    //   ind.push(<option>{industries[i].name}</option>);
    // }

    return (
      <div>
        <Helmet>
          <title>Job Seeker Form || Hollyface</title>
          <meta
            name="description"
            content="Welcome to Hollyface Overseas Recruitment & placement Pvt. Ltd.
          Looking for a job abroad? Want to fly your skills and fly abroad? Here you are..."
          />
          <h1>Hollyface, Job seeker page</h1>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="og:type" content="Job seeker page" />
          <meta name="og:url" content="https://hollyface.com/" />
        </Helmet>
        {/* <TopBanner /> */}
        <NavbarOthers />

        <PageTitle text="Job Seeker form" mt="mt-10" />
        <div className="text-xl text-center text-gray-700">
          Are you looking for a job? <br></br>Please fill the form below and we
          will get back to you if there is a suitable opening
        </div>

        <Seeker />
      </div>
    );
  }
}
