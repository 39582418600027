import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Hero extends Component {

    render() {
        return (
            <div className="relative bg-white h-auto">
                <div className="max-w-full mx-auto">
                    <div className="pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full ">

                        <main className="mt-10 mx-auto max-w-full px-4 sm:px-6 lg:px-8">
                            <div className="sm:text-center lg:text-left">
                                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-grey-800 sm:text-5xl sm:leading-10 md:text-6xl">
                                    Hollyface Overseas
                            <br></br>
                                    <span className="text-indigo-600 text-3xl md:text-4xl">Recruitment &amp; Placement Pvt. Ltd.</span>
                                </h2>
                                <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Talent Solutions in a competitive world</p>
                                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <div className="rounded-md shadow">
                                        <Link to="/seeker"> <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                                            Job Seeker</span></Link>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-3">
                                        <Link to="/provider"> <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                                            Job Provider</span></Link>
                                    </div>

                                </div>
                                <div className="mt-5 ml-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <a href={process.env.PUBLIC_URL + '/assets/Hollyface_Brochure.pdf'} download className="flex text-lg text-indigo-600 hover:text-gray-700 hover:underline transition duration-300"><img alt='download' className='w-5 h-5 mr-2' src={process.env.PUBLIC_URL + '/assets/images/down-arrow.svg'}></img> Download E-Brochure</a>
                                </div>
                            </div>
                        </main>

                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 mt-10">
                    <img className="heroImage w-full px-10 object-fit" src={process.env.PUBLIC_URL + '/assets/images/interview.svg'} alt=""></img>
                </div>
            </div>
        )
    }

}