import React, { Component } from 'react'

export class PageTitle extends Component {
    render() {
        return (
            <div className={'inline-block w-auto pb-2 ' + this.props.mt + ' text-3xl sm:mx-16 mx-4 pr-8 font-bold text-indigo-700 border-b-2 border-gray-400'}>
                {this.props.text}
            </div>
        )
    }
}

export default PageTitle
