import React, { Component } from 'react'

export class TopBanner extends Component {
    render() {
        return (
            <div className='flex flex-wrap bg-gray-100 w-full justify-around'>
                <div className='md:flex hidden w-full md:w-auto py-2 justify-center items-center px-2 '>
                    <div className='w-full flex flex-row justify-center'>
                        <img alt='Logo' className='h-16 ' src={process.env.PUBLIC_URL + '/assets/images/govt-logo.png'}></img>
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <img alt='Logo' className='h-16 ' src={process.env.PUBLIC_URL + '/assets/images/iso-logo.png'}></img>
                    </div>
                </div>
                <div className='flex w-full md:max-w-lg px-2 justify-center '>
                    <img alt='Logo' className='h-16 pt-2' src={process.env.PUBLIC_URL + '/assets/images/logo3.png'}></img>
                    <div className='ml-5 my-auto text-shadow-md text-left leading-7 text-2xl font-bold text-indigo-700'>Hollyface Overseas<br></br><span className='text-grey-800'>Recruitment &amp; Placement Pvt. Ltd.</span></div>
                </div>
                <div className='flex flex-col w-full md:w-auto py-2 justify-center items-center px-2 '>
                    <div className='w-full flex flex-row justify-center'>
                        <img alt='phone-number' src={process.env.PUBLIC_URL + '/assets/images/phone.svg'} className='w-5 h-5'></img>
                        <span className='text-md ml-2 font-semibold text-indigo-700'>(+91) 033-25372424</span>
                    </div>
                    <div className='flex w-full mt-2 justify-center'>
                        <a href={process.env.PUBLIC_URL + '/assets/Hollyface_Brochure.pdf'} download className="flex text-lg text-indigo-700 hover:text-gray-700 hover:underline transition duration-300"><img alt='download' className='w-5 h-5 mr-2' src={process.env.PUBLIC_URL + '/assets/images/down-arrow.svg'}></img> Download E-Brochure</a>
                    </div>
                </div>

            </div>
        )
    }
}

export default TopBanner
