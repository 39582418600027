import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import TopBanner from "./TopBanner";

export default class NavbarHome extends Component {
  componentDidMount() {
    document.getElementById("nav-toggle").addEventListener("click", () => {
      document.getElementById("nav-list").classList.toggle("hidden");
    });
  }

  render() {
    return (
      <nav
        className="bg-gray-100 shadow-lg z-50 sticky w-full top-0 "
        role="navigation"
      >
        <TopBanner />
        <div className="container mx-auto px-4 py-2 flex flex-wrap justify-between md:flex-no-wrap ">
          <div className="mr-4 md:mr-8 md:hidden">
            <Link to="/">
              <span className="text-indigo-600 text-2xl font-bold">
                Hollyface
              </span>
            </Link>
          </div>
          <div className="ml-auto md:hidden" id="nav-toggle">
            <button
              className="flex items-center px-3 py-2 border rounded"
              type="button"
            >
              <svg
                className="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className="w-full md:w-auto md:flex-grow md:flex md:justify-center hidden"
            id="nav-list"
          >
            <ul className="cursor-pointer flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 md:border-0">
              <li>
                <ScrollLink
                  to="CarouselHero"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <div className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    HOME
                  </div>
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="AboutUs"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    ABOUT US
                  </span>
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="ContactUs"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    CONTACT US
                  </span>
                </ScrollLink>
              </li>
              {/* <li>
                <ScrollLink to="JobUpdates">
                  <span className="md:hidden block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    CURRENT OPENINGS
                  </span>
                </ScrollLink>
              </li> */}
              <li>
                <Link to="/clients">
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    CLIENTS
                  </span>
                </Link>
              </li>
            </ul>
            {/* <ul className="cursor-pointer md:flex md:flex-col mt-4 pt-4 md:items-center justify-center md:ml-auto md:mx-0 md:mt-0 md:pt-0 md:mr-4 lg:mr-8 hidden">
              <li>
                <ScrollLink
                  to="JobUpdates"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="bg-indigo-600 p-2 rounded-lg text-white text-base font-bold uppercase hover:bg-indigo-800 hover:shadow-md ">
                    Current Openings
                  </span>
                </ScrollLink>
              </li>
            </ul> */}
            <ul className="flex flex-col -mx-4 md:flex-row md:items-center md:mx-0 md:ml-auto md:mt-0 md:pt-0">
              <li>
                <Link to="/seeker">
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg text-red-600 hover:font-bold hover:shadow-md font-semibold hover:bg-red-600 hover:text-white mx-4 md:mx-0 transition duration-300">
                    JOB SEEKER
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/provider">
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    JOB PROVIDER
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/services">
                  <span className="block px-4 py-1 md:p-2 lg:px-4 rounded-lg hover:text-indigo-600 hover:shadow-md hover:font-bold font-semibold text-gray-800 mx-4 md:mx-0 transition duration-300">
                    OUR SERVICES
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
