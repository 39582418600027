import React, { Component } from "react";
import NavbarOther from "./base/NavbarOther";
import PageTitle from "./base/PageTitle";
import ReactPaginate from "react-paginate";
// import TopBanner from './base/TopBanner'
import { Helmet } from "react-helmet";

const Card = (props) => (
  <div className="flex flex-col my-4 sm:px-3 justify-center items-center max-w-sm mx-auto">
    <div className="flex justify-center bg-blue-300 h-32 w-32 rounded-lg shadow-lg bg-cover bg-center content-center p-3 ">
      <img
        alt="company-logo"
        className="object-contain"
        src={process.env.PUBLIC_URL + "/assets/clients/" + props.image}
      ></img>
    </div>

    <div className="w-32 bg-blue-100 -mt-6 shadow-md rounded-lg overflow-hidden hover:shadow-xl transtion duration-300">
      <h3 className="h-12 flex text-center font-bold text-sm uppercase tracking-wide text-gray-800  px-2 justify-center items-center">
        {props.name}
      </h3>

      <div className="flex items-center justify-center  px-3 bg-gray-300">
        <span className="text-gray-800 font-bold ">{props.country}</span>
      </div>
    </div>
  </div>
);

const company = [
  {
    name: "Gulf Eng Company",
    image: "gulf com.png",
    country: "Kuwait",
  },
  {
    name: "KCC Eng & Con",
    image: "kcc.png",
    country: "Kuwait",
  },
  {
    name: "burgan rig",
    image: "burgan.PNG",
    country: "Kuwait",
  },
  {
    name: "food masters rest & catr",
    image: "food.PNG",
    country: "Kuwait",
  },
  {
    name: "Gulf catering co w.l.l.",
    image: "gulfcc.png",
    country: "Kuwait",
  },
  {
    name: "Sbc gen trad & cont",
    image: "sbc.png",
    country: "Kuwait",
  },
  {
    name: "Al Ahlia intg gen trad co",
    image: "al ahlia.png",
    country: "Kuwait",
  },
  {
    name: "heisco",
    image: "HEISCO_LOGO_WHITE.svg",
    country: "Kuwait",
  },
  {
    name: "Hotel crowne plz",
    image: "crown.png",
    country: "Kuwait",
  },
  {
    name: "Aqar intl gen trd",
    image: "aqar.jpg",
    country: "Kuwait",
  },
  {
    name: "york airc & refg co",
    image: "york2.png",
    country: "Kuwait",
  },

  {
    name: "Al-Ghamin Company",
    image: "alghanim.png",
    country: "Kuwait",
  },
  {
    name: "NBTC Company",
    image: "NBTC com.jpg",
    country: "Kuwait",
  },
  {
    name: "Fawaz Refg and Arc Co.",
    image: "fawaz.png",
    country: "U.A.E.",
  },
  {
    name: "Reliance Cont Co.",
    image: "rel co co.png",
    country: "U.A.E.",
  },
  {
    name: "Shapoorji Pallonji Co",
    image: "icon_splogo_blue.svg",
    country: "U.A.E.",
  },
  {
    name: "William Hare L.L.C",
    image: "william-hare-logo.png",
    country: "U.A.E.",
  },
  {
    name: "Drake & Scull L.L.C.",
    image: "drake and skull.jpg",
    country: "U.A.E.",
  },
  {
    name: "United Precast L.L.C",
    image: "united precast.png",
    country: "U.A.E.",
  },
  {
    name: "Fabtech intl L.L.C.",
    image: "fabtech.png",
    country: "U.A.E.",
  },
  {
    name: "Gulf Star Cooling L.L.C",
    image: "gulf star.png",
    country: "U.A.E.",
  },
  {
    name: "Elite Construction",
    image: "elite.png",
    country: "U.A.E.",
  },
  {
    name: "Delta Emirate Building Con",
    image: "delta.jpg",
    country: "U.A.E.",
  },
  {
    name: "Fairway Cat Services",
    image: "fairway_logo.jpg",
    country: "U.A.E.",
  },
  {
    name: "BELHASA PROJECT L.L.C",
    image: "belhasa.PNG",
    country: "U.A.E.",
  },

  {
    name: "Convrgnt value eng",
    image: "conv.jpg",
    country: "U.A.E.",
  },
  {
    name: "United MAsters ec",
    image: "united mast.png",
    country: "U.A.E.",
  },
  {
    name: "Al rams prime construction ",
    image: "al rams.png",
    country: "U.A.E.",
  },
  {
    name: "vision world engineering",
    image: "vision.PNG",
    country: "U.A.E.",
  },
  {
    name: "Ag facilities solution",
    image: "ag.png",
    country: "U.A.E.",
  },
  {
    name: "Goodland interiors ",
    image: "good.PNG",
    country: "U.A.E.",
  },
  {
    name: "Cicon epoxy & steel plant",
    image: "cicon.png",
    country: "U.A.E.",
  },
  {
    name: "Al muhanad mech.L.L.C",
    image: "muhanad.png",
    country: "U.A.E.",
  },
  {
    name: "abu dhabi nat hotel",
    image: "andh.jpg",
    country: "U.A.E.",
  },
  {
    name: "Cosma",
    image: "cosma2.jpg",
    country: "Saudi Arabia",
  },
  {
    name: " Saudi Oger Ltd.",
    image: "saudi oger.png",
    country: "Saudi Arabia",
  },
  {
    name: "Saudi Binladen Grp",
    image: "saudi binladen grp.jpg",
    country: "Saudi Arabia",
  },

  {
    name: "Baytur Construction",
    image: "saudi baytur.png",
    country: "Saudi Arabia",
  },
  {
    name: "Larsen & Rourbo L.l.c",
    image: "lt.jpg",
    country: "Saudi Arabia",
  },
  {
    name: "AEtcon",
    image: "aet.PNG",
    country: "Saudi Arabia",
  },

  {
    name: "Blue Star Co",
    image: "Blue star.png",
    country: "Qatar",
  },
  {
    name: "KST Construction",
    image: "ksconstructions.PNG",
    country: "Myanmar",
  },
  {
    name: "Rukan Al Yaqeen",
    image: "ray group logo.png",
    country: "Oman",
  },

  {
    name: "Buildtech construction",
    image: "buildtech.png",
    country: "Oman",
  },
  {
    name: "tawoos L.L.C.",
    image: "tawoos.PNG",
    country: "Oman",
  },
  {
    name: "Al naba services L.L.C.",
    image: "al naba.jpg",
    country: "Oman",
  },
  {
    name: "Manser & Saxon Com",
    image: "Manser and saxon.png",
    country: "Mauritus ",
  },
  {
    name: "Manser & Saxon Com",
    image: "Manser and saxon.png",
    country: "Seychelles",
  },

  {
    name: "Kooheji Contractors",
    image: "kooheji.png",
    country: "Bahrain",
  },
  {
    name: "mcsc co w.l.l.",
    image: "mcsc.png",
    country: "Bahrain",
  },
  {
    name: "Ahmed mansoor aali",
    image: "al.jpg",
    country: "Bahrain",
  },
  {
    name: "Punj LLoyd Oil & Gas ",
    image: "Punj llyod.jpg",
    country: "Malaysia",
  },
];

export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      perPage: 60,
      currentPage: 0,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.changeData = this.changeData.bind(this);
  }

  changeData() {
    const slice = company.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    const postData = slice.map((elem) => (
      <Card image={elem.image} name={elem.name} country={elem.country} />
    ));

    this.setState({
      pageCount: Math.ceil(company.length / this.state.perPage),
      postData,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.changeData();
      }
    );
    window.location.href = "#top";
  };

  componentDidMount() {
    this.changeData();
    window.scrollTo({ top: 0 });
  }

  render() {
    var items = [];
    for (var i = 0; i < company.length; i++) {
      items.push(
        <Card
          image={company[i].image}
          name={company[i].name}
          country={company[i].country}
        />
      );
    }
    return (
      <div className="w-full" id="top">
        <Helmet>
          <title>Clients || Hollyface</title>
          <meta
            name="description"
            content="Welcome to Hollyface Overseas Recruitment & placement Pvt. Ltd.
         Here is a list of clients of Hollyface"
          />
          <h1>Hollyface, Clients</h1>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="og:type" content="Clients page" />
          <meta name="og:url" content="https://hollyface.com/" />
        </Helmet>
        {/* <TopBanner /> */}
        <NavbarOther />
        <PageTitle text="Our Clients" mt="mt-10" />
        <div className="flex flex-wrap mt-4 px-3 py-2 sm:mx-3">
          {this.state.postData}
        </div>
        {/* <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"flex justify-center m-10"}
                    subContainerClassName={"mx-2 "}
                    activeLinkClassName={"bg-gray-400"}
                    pageLinkClassName={"p-3 mx-2 hover:bg-indigo-500 hover:text-white border border-gray-400 rounded-md transition duration-300"}
                    previousLinkClassName={"p-3 mx-2 bg-indigo-500 text-white hover:bg-indigo-700 rounded-md transition duration-300"}
                    nextLinkClassName={"p-3 mx-2 bg-indigo-500 text-white hover:bg-indigo-700 rounded-md transition duration-300"}
                /> */}
      </div>
    );
  }
}
