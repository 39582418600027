import React, { Component } from 'react';

export default class ContactUs extends Component {
  render() {
    return (
      <div>
        <div className="text-gray-700 body-font relative mt-20" id='contactus'>
          <div className="absolute inset-0 bg-gray-300 contact">
            <iframe width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" title="map" scrolling="no" src="https://maps.google.com/maps?q=HOLLYFACE+OVERSEAS+RECRUITMENT+%26+PLACEMENT+PVT.+LTD.&t=&z=18&ie=UTF8&iwloc=&output=embed" ></iframe>
          </div>
          <div className="container px-5 py-24 mx-auto flex shadow-md justify-center">
            <div className="lg:w-1/3 md:w-1/2 w-4/5 bg-gray-300 shadow-md rounded-lg p-8 flex flex-col md:ml-auto  md:mt-0 relative z-10 ">
              <h2 className="text-indigo-700 text-3xl font-bold mb-1 title-font">Contact us</h2>
              <p className="leading-relaxed mb-5 text-black-900 ">Feel free to leave us a message</p>
              <h2 className="text-blue-800">Email id</h2>
              <input className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4" placeholder="Email" type="email"></input>
              <h2 className="text-blue-800">Phone no</h2>
              <input className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4" placeholder="Phone no" type="text"></input>
              <h2 className="text-blue-800">Message</h2>
              <textarea className="bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-indigo-500 text-base px-4 py-2 mb-4 resize-none" placeholder="Message"></textarea>
              <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit</button>
              <p className="text-md text-gray-500 mt-3 text-center">Thank You</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="bg-indigo-400 text-white w-full md:w-1/3 p-10 flex ">
            <img className="h-10 w-10" alt="location" src={process.env.PUBLIC_URL + '/assets/images/location.svg'}></img>
            <div className="ml-3">
              <h1 className="text-xl font-bold">Visit Our Company</h1>
              <div className="mt-2 mb-2">
              2 No. Chandigarh Main Road (2nd floor of SBI ATM building)<br></br>P.O – Madhyamgram Bazar<br></br>
              Kolkata - 700130 , India</div>
            </div>
          </div>
          <div className="bg-indigo-500  text-white w-full sm:w-1/3 p-10 flex">
            <img className="h-10 w-10" alt="location" src={process.env.PUBLIC_URL + '/assets/images/call.svg'}></img>
            <div className="ml-3">
              <h1 className="text-xl font-bold mt-2">Have a question? Call us</h1>
              <div className="mt-2 mb-2">(+91) 033-25372424 </div>
            </div>
          </div>
          <div className="bg-indigo-400  text-white w-full sm:w-1/3 p-10 flex">
            <img className="h-10 w-10" alt="location" src={process.env.PUBLIC_URL + '/assets/images/mail.svg'}></img>
            <div className="ml-3">
              <h1 className="text-xl font-bold mt-2">Get in touch</h1>
              <div className="mt-2 mb-2">info.hollyface@gmail.com</div>
              <div className="mt-2 mb-2">info@hollyface.com</div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}
