import React, { Component } from "react";
import NavbarOther from "./base/NavbarOther";
import PageTitle from "./base/PageTitle";
// import TopBanner from './base/TopBanner'
import { Helmet } from "react-helmet";

const Item = (props) => (
  <div className="flex flex-col my-4 sm:px-3 justify-center items-center max-w-sm mx-auto">
    <div className="flex justify-center h-48 w-56 rounded-lg shadow-lg bg-cover bg-center content-center p-3 ">
      <img
        alt="company-logo"
        className="object-contain"
        src={process.env.PUBLIC_URL + "/assets/industries/" + props.image}
      ></img>
    </div>

    <div className="w-48 bg-gray-300 -mt-6 shadow-md rounded-lg overflow-hidden hover:shadow-xl transtion duration-300">
      <h3 className="flex py-2 text-center font-bold text-sm uppercase tracking-wide text-gray-800 h-12 px-2 justify-center items-center">
        {props.name}
      </h3>
    </div>
  </div>
);

const industries = [
  {
    name: "Automobile",
    image: "automobile.jpg",
  },
  {
    name: "Aluminium & Cladding",
    image: "aluminium.jpg",
  },
  {
    name: "Branded Hotels",
    image: "hotels.jpg",
  },
  {
    name: "Construction & Infrastructure",
    image: "construction.jpg",
  },
  {
    name: "Hospitality",
    image: "hospitality.jpg",
  },
  {
    name: "Facility management",
    image: "facility.jpg",
  },
  {
    name: "Heavy Machinery / Industrial Products",
    image: "machine.jpg",
  },
  {
    name: "Healthcare",
    image: "healthcare.jpg",
  },

  {
    name: "IT",
    image: "it.jpg",
  },
  {
    name: "Oil & Gas/ Refinery/ Petrochemical",
    image: "oil.jpg",
  },
  {
    name: "MEP",
    image: "plumbing.jpg",
  },
  {
    name: "Production/ Manufacturing",
    image: "production.jpg",
  },
  {
    name: "Retail",
    image: "retail.jpg",
  },
  {
    name: "Ship Repair & Maintenance",
    image: "shipping.jpg",
  },
  {
    name: "Shutdown Maintenance",
    image: "shutdown.PNG",
  },
];

export default class Services extends Component {
  render() {
    var inds = [];
    for (var i = 0; i < industries.length; i++) {
      inds.push(<Item image={industries[i].image} name={industries[i].name} />);
    }
    return (
      <div className="w-full mb-10">
          <Helmet>
          <title>Services || Hollyface</title>
          <meta
            name="description"
            content="Welcome to Hollyface Overseas Recruitment & placement Pvt. Ltd.
          Lets have a look at the services that we provide..."
          />
          <h1>Hollyface, Job seeker page</h1>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="og:type" content="Job seeker page" />
          <meta name="og:url" content="https://hollyface.com/" />
        </Helmet>
        {/* <TopBanner /> */}
        <NavbarOther />
        <PageTitle text="Recruitment" mt="mt-10" />
        <section className="bg-gray-800 mx-6 rounded-md shadow-md h-1/2 mt-10">
          <div className="container mx-auto px-4 py-6">
            <div className="lg:flex items-center">
              <div className="lg:w-1/2">
                {/* <h2 className="flex justify-center text-gray-100 text-3xl font-bold">Recruitment</h2> */}
                <h4 className="flex justify-left text-gray-100 text-xl font-bold">
                  Recruitment Procedure
                </h4>
                <p className="text-gray-400 mt-4 text-justify ">
                  <strong className="text-lg ">
                    To win in the marketplace you must first win in recruitments
                  </strong>
                  <br></br>Company is an empty box without its employees.
                  Employees are the fuel that runs the business engine.
                  Recruitment is not merely a function for organization; it is
                  the most important one. Business goals can be attained only
                  when there is a competent workforce to back up the plans.
                  Hollyface Overseas Recruitment & Placement Pvt. Ltd. helps the
                  organizations to find the best recruits for their job
                  vacancies.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Our Recruitment Procedure</strong>
                  <br></br>We have a simple recruitment process. The process is
                  initiated only after the client and Hollyface Overseas
                  Recruitment & Placement Pvt. Ltd. execute an agency level
                  agreement between them. The agreement states the terms and
                  conditions regarding demand and supply of manpower from India.
                  Both parties must sign an acceptance to stated terms and
                  conditions. Once the formalities are done, manpower search
                  will begin.
                </p>
              </div>

              <div className="mt-8 lg:mt-0 lg:w-1/2">
                <div className="flex items-center justify-center">
                  <div className="max-w-lg p-4">
                    <img
                      className="w-full h-64 object-fit object-center rounded-md"
                      src={process.env.PUBLIC_URL + "/assets/images/rec.svg"}
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-800 mx-6 rounded-md shadow-md h-1/2 mt-10">
          <div className="container mx-auto px-4 py-6">
            <div className="lg:flex items-center">
              <div className="lg:w-1/1">
                <h2 className="flex justify-center text-gray-100 text-3xl font-bold">
                  Employers Part
                </h2>

                <p className="text-gray-400 mt-4 text-justify ">
                  <strong className="text-lg">Demand Letter</strong>
                  <br></br>The employer needs to issue a demand letter in favour
                  of Hollyface Overseas Recruitment & Placement Pvt. Ltd.
                  clearly stating the job categories for which workforce is
                  sought, number of employees needed under each category,
                  monthly salary scales, desired duration of work contract and
                  provision for other amenities such as food, medical, air
                  passage, transport, accommodation, etc.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Power of Attorney</strong>
                  <br></br>Employer or principal authority needs to issue a
                  letter of authority in favour of Hollyface Overseas
                  Recruitment & Placement Pvt. Ltd. addressed to the Consulate
                  General of the concerned Embassy in India stating that M/s.
                  Hollyface Overseas Recruitment & Placement Pvt. Ltd. is their
                  bonafide Manpower Recruiting Agent fully authorised to deal
                  with all visa related matters, submissions and delivery with
                  the concerned Embassy. (This process is applicable for
                  Middle-East and Far-East countries only.)
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">
                    Specimen Employment Contract
                  </strong>
                  <br></br>Employer has to sign a standard employment contract
                  between employer and employee.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-800 mx-6 rounded-md shadow-md h-1/2 mt-10">
          <div className="container mx-auto px-4 py-6">
            <div className="lg:flex items-center">
              <div className="lg:w-1/1">
                <h2 className="flex justify-center text-gray-100 text-3xl font-bold">
                  Agents Part
                </h2>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Selection</strong>
                  <br></br>Hollyface Overseas Recruitment & Placement Pvt. Ltd.
                  prefers the selection of applicants by oral or practical tests
                  conducted by the employer or any of his authorised
                  representatives. We arrange the accommodation, transport and
                  other logistics needed by the authorised representative. If we
                  are entrusted with selection and testing of workers, we would
                  conduct that with the help of field specific experts for the
                  professional or technical category. Selection is held under
                  direct supervision of our own professional executives.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Medical Check-up</strong>
                  <br></br>Once the selection of candidates is finalized, we
                  send the candidates for medical check-up at a medical center
                  authorised by the concerned Embassies for the country of
                  employment.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Visa Application</strong>
                  <br></br> We will apply for the Visas of the selected
                  employees as authorised representatives of the employer.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">Emigration and Ticketing</strong>
                  <br></br>We will obtain the emigration clearance for the
                  candidates from concerned Government Departments. Employers
                  need to send PTA or Remit necessary for travelling expenses in
                  favour of Hollyface Overseas Recruitment & Placement Pvt. Ltd.
                  to facilitate travelling of workforce as per schedule.
                </p>
                <p className="text-gray-400 mt-4 text-justify">
                  <strong className="text-lg">
                    Basic Orientation prior Departure
                  </strong>
                  <br></br>Selected candidates are provided basic level
                  orientation by Hollyface Overseas Recruitment & Placement Pvt.
                  Ltd. prior to travelling abroad. During this orientation
                  program the workers are informed about their roles and
                  responsibilities while working at an overseas location. They
                  are also informed about the expected work environment and
                  salient features of labour laws for the country of employment.
                </p>
              </div>
            </div>
          </div>
        </section>

        <PageTitle text="Industries We Serve" mt="mt-10" />

        <div className="flex flex-wrap mt-4 px-3 py-2 sm:mx-3">{inds}</div>

        <PageTitle text="Services" mt="mt-10" />
        <div className="w-full h-3/4 md:px-20 px-1 ">
          <img
            className="max-w-5xl mx-auto"
            src={process.env.PUBLIC_URL + "/assets/images/service2.svg"}
          ></img>
        </div>
      </div>
    );
  }
}
