import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Popup from "reactjs-popup";

import PageTitle from './PageTitle'


const Job = props => (
    <div className="max-w-xl w-auto px-8 py-4 bg-gray-200 rounded-lg shadow-md mb-5 mx-3">
        {/* <div className="flex justify-between items-center">
            <span className="font-light text-gray-600 text-sm">Hollyface Overseas Recruitment & Placement Pvt. Ltd.</span>
        </div> */}

        <div className="mt-2">
            <span className="text-2xl text-gray-700 font-bold hover:text-gray-600">{props.content.jobName}</span>
            <p className="mt-2 text-blue-800 font-bold">Date of interview: {props.content.interview}</p>
            <p className="mt-2 text-gray-600">Category: {props.content.category}</p>
            <p className="mt-2 text-gray-600">Experience required: {props.content.experience}</p>
            <p className="mt-2 text-gray-600">Salary: {props.content.salary}</p>
            <p className="mt-2 text-gray-600">Location: {props.content.location}</p>
        </div>

        <div className="flex justify-between items-center mt-4">
            <Popup
                trigger={<button className="button mt-2 rounded-full text-red-600 hover:text-red-800 hover:underline"> Read Job Description </button>}
                modal={true}
                closeOnDocumentClick={true}
                position="center center"
                contentStyle={{ width: "90%", borderRadius: "20px" }}
            >
                {close => (
                    <div className='mx-4 p-6'>

                        <div>

                            <h1 className='inline-block w-auto pb-2 text-xl font-bold text-indigo-700 border-b-2 border-gray-400'>Job Description</h1>
                            <span className="inline-block relative rounded-md float-right bg-gray-200 hover:bg-gray-400 border border-gray-400 py-1 px-2 cursor-pointer" onClick={close}>&times;</span>
                        </div>
                        <div className='w-full mt-3'>
                            <pre className='font-sans text-md'>
                                {props.content.jobDescription}
                            </pre>
                        </div>
                        <center><Link to='/seeker'><button type='submit' className='mt-4 py-2 px-4 bg-indigo-500 rounded-lg text-white text-lg border-0 hover:shadow-md hover:bg-indigo-700 transition duration-300'>Apply</button></Link></center>
                    </div>
                )}
            </Popup>
        </div>

        <Link to='/seeker'><button type='submit' className='mt-4 py-2 px-4 bg-indigo-500 rounded-lg text-white text-lg border-0 hover:shadow-md hover:bg-indigo-700 transition duration-300'>Apply</button></Link>

    </div>
)

export class JobUpdates extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        axios.get('https://hollyface.com/api/newjobs').then((response) => {
            this.setState({ data: response.data })
        })
    }

    render() {
        var items = []
        for (var i = 0; i < this.state.data.length; i++) {
            items.push(<Job content={this.state.data[i]} />)
        }
        return (
            <div id='jobupdates'>
                <PageTitle text='Job Updates' mt='mb-10 mt-20' />
                <div className='ml-10 flex flex-wrap'>
                    {items}
                </div>
            </div>
        )
    }
}

export default JobUpdates
